export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    resource: 'Auth',
    action: 'read',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Admin',
        route: 'Admin',
        icon: 'FileIcon',
        resource: 'Admin',
        action: 'read',
      },
    ],
  },
]
